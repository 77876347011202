<template>
  <v-app
    :class="[
      getKeyboardOpen ? classes + ' keyboard-open' : classes,
      { 'platform-ios': platformIos }
    ]"
  >
    <Navbar v-if="showApp" :key="key" />
    <v-navigation-drawer
      v-if="showApp"
      v-model="drawerLeft"
      touchless
      left
      app
      clipped
      class="categories"
    >
      <CategoryTree />
    </v-navigation-drawer>
    <v-navigation-drawer
      v-if="showApp"
      v-model="drawerRight"
      disable-resize-watcher
      touchless
      temporary
      clipped
      right
      app
      width="600"
      id="cart-right-drawer"
      class="cart-drawer white"
    >
      <div class="cart-info">
        <CartInfoList :isCart="true" class="infos py-3 pb-sm-0" />
        <CartItemList
          class="items-list"
          :full="$vuetify.breakpoint.xs"
          :checkMobile="iosMobile"
        />
        <CartSummary
          class="summary py-0"
          :class="{ 'cart-item-list-no-checkout-osx': iosMobile }"
        />
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      class="links-list white"
      v-if="cordovaPlatform"
      v-model="drawerLinks"
      touchless
      right
      clipped
      app
      width="auto"
      @close="drawerLinks = false"
    >
      <MenuLinksList :links="footerLinks" @close="drawerLinks = false" />
    </v-navigation-drawer>
    <v-main>
      <!-- platform: {{ cordovaPlatform }} is cordova:{{ isCordova }} -->
      <!-- <Tutorial v-if="isCordova" /> -->
      <div class="content-wrap">
        <v-skeleton-loader
          type="actions, image, article,image"
          v-if="loading"
          :loading="loading"
        ></v-skeleton-loader>
        <router-view v-else></router-view>
      </div>
      <Footer v-if="showApp" :links="footerLinks" />
    </v-main>

    <ScrollTop />
    <SsoUserCard />
    <!-- <GiftCertificateWindow /> -->
  </v-app>
</template>
<script>
import CartSummary from "@/components/cart/CartSummary.vue";
import CartInfoList from "@/components/cart/CartInfoList.vue";
import CartItemList from "@/components/cart/CartItemList.vue";
import CategoryTree from "@/components/navigation/CategoryTree.vue";
import CheckDisclaimerDialog from "@/components/profile/CheckDisclaimerDialog.vue";

import MenuLinksList from "@/components/navigation/MenuLinksList.vue";
import SsoUserCard from "@/components/profile/SsoUserCard.vue";
// import GiftCertificateWindow from "@/components/gift/GiftCertificateWindow.vue";

import login from "~/mixins/login";
import version from "~/mixins/version";
import CmService from "~/service/cmService";
import UserService from "~/service/userService";

import { mapActions, mapGetters } from "vuex";
import findIndex from "lodash/findIndex";

import FingerprintJS from "@fingerprintjs/fingerprintjs";
import ScrollTop from "./components/ScrollTop.vue";

export default {
  name: "App",
  components: {
    CategoryTree,
    CartInfoList,
    CartSummary,
    CartItemList,
    Footer: () =>
      process.env.CORDOVA_PLATFORM
        ? import("@/components/navigation/BottomNavBar.vue")
        : import("@/components/navigation/Footer.vue"),
    Navbar: () =>
      process.env.CORDOVA_PLATFORM
        ? import("@/components/navigation/NavbarMobile.vue")
        : import("@/components/navigation/Navbar.vue"),
    MenuLinksList,
    SsoUserCard,
    ScrollTop
    // ,
    // GiftCertificateWindow
  },
  mixins: [login, version],
  data() {
    return {
      load404: false,
      initialHref: window.location.href,
      loading: true,
      footerLinks: [],
      key: 1,
      classes: process.env.CORDOVA_PLATFORM ? "is-cordova" : "",
      platformIos: false,
      keyboardOpen: false,
      iosMobile: false
    };
  },
  metaInfo: {
    title: "DupliClick",
    titleTemplate: "%s - DupliClick",
    meta: [
      { charset: "utf-8" },
      {
        vmid: "description",
        name: "description",
        content:
          "DupliClick Spesa Online è il nuovissimo servizio di DupliClick che consente di fare acquisti su un vasto assortimento di prodotti in totale comodità da casa, con semplicità e rapidità"
      },
      {
        name: "keywords",
        content: "DupliClick Spesa Online supermercato"
      },
      { name: "viewport", content: "width=device-width, user-scalable=no" },
      { name: "author", content: "Digitelematica s.r.l." },
      // Open Graph Metas
      {
        property: "og:locale",
        content: "it_IT"
      },
      {
        property: "og:title",
        content: "%s - DupliClick"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:url",
        content: location.href
      },
      {
        property: "og:image",
        content: "https://" + window.location.host + "/logo/social-logo.png"
      },
      {
        property: "og:image:alt",
        content:
          "DupliClick Spesa Online è il nuovissimo servizio di DupliClick che consente di fare acquisti su un vasto assortimento di prodotti in totale comodità da casa, con semplicità e rapidità"
      },
      {
        property: "og:site_name",
        content: "DupliClick"
      },
      // Twitter Metas
      {
        name: "twitter:card",
        content: "summary_large_image"
      },
      {
        name: "twitter:title",
        content: "%s - DupliClick"
      },
      {
        name: "twitter:image",
        content: "https://" + window.location.host + "/logo/social-logo.png"
      },
      {
        name: "twitter:url",
        content: location.href
      }
    ]
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    ...mapGetters({
      needRestart: "needRestart",
      isAuthenticated: "cart/isAuthenticated"
    }),
    drawerLeft: {
      get() {
        return this.$store.state.app.drawerLeft;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerLeft", value);
      }
    },
    drawerRight: {
      get() {
        return this.$store.state.app.drawerRight;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerRight", value);
      }
    },
    drawerLinks: {
      get() {
        return this.$store.state.app.drawerLinks;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerLinks", value);
      }
    },
    getUserId() {
      return this.$store.state.cart.cart.user
        ? this.$store.state.cart.cart.user.userId
        : undefined;
    },
    getKeyboardOpen: function() {
      return this.keyboardOpen;
    },
    showApp: function() {
      return !this.load404;
    }
  },
  methods: {
    ...mapActions({
      updateAuth: "cart/updateAuth",
      resetFilters: "category/resetFilters",
      updateLastStartTime: "app/updateLastStartTime",
      updateFingerprint: "app/updateFingerprint"
    }),
    checkOsx() {
      var userAgent = [];
      var version = [];
      var versionNumber = 0;
      if (navigator.userAgent.indexOf("iPhone") > -1) {
        userAgent = navigator.userAgent.split(" ");
        userAgent.filter(ua => {
          if (ua.indexOf("Version/") > -1) {
            version = ua.split("/");
          }
        });
        versionNumber = parseFloat(version[1]);
        if (version[0] == "Version") {
          if (versionNumber < 15) {
            this.iosMobile = true;
          } else {
            this.iosMobile = false;
          }
        } else {
          this.iosMobile = false;
        }
      }
    },
    fetchFooterLinksMenu() {
      CmService.getMenuByLocation("footer").then(
        data => (this.footerLinks = data)
      );
    },
    async onResume() {
      var _this = this;
      var hash;
      global.EventBus.$emit("resume");
      setTimeout(async function() {
        hash = findIndex(global.config.hashNotReload, function(o) {
          return window.location.hash.indexOf(o) !== -1;
        });
        var needRestart =
          new Date().getTime() - _this.$store.state.app.lastStartTime >
          global.config.reloadAfterMillis;
        if (hash === -1 && needRestart) {
          global.EventBus.$emit("restart");
        } else {
          if (
            hash === -1 &&
            window.sessionStorage.getItem("is-authenticated")
          ) {
            await _this.loadCart();
          }
          global.EventBus.$emit("reload");
        }
      }, 100);
    },
    async handleLogin() {
      this.doLogin("response419");
    },
    async onDeepLinks(path, queryString) {
      var _this = this;
      var currentQuery = {};
      console.log("onDeepLinks :::: ", path);
      console.log("onDeepLinks queryString :::: ", queryString);
      console.log("_this.$router :::: ", _this.$router);
      setTimeout(async function() {
        if (queryString) {
          currentQuery.q = queryString.replace("q=", "").replace(/%20/g, " ");
          console.log("onDeepLinks currentQuery :::: ", currentQuery);
          _this.$router.push({
            path: "/search",
            name: "Search",
            query: currentQuery
          });
        } else {
          _this.$router.push(path);
        }
      }, 100);
    },
    resolveRoute(data) {
      var _this = this;
      var currentQuery = {};
      console.log("resolveRoute :::: ", data);
      if (global.config.domainWhitelist.includes(data.host)) {
        let queryString = data.queryString;
        let resolved = this.$router.resolve({
          path: data.path
        });
        if (
          resolved &&
          resolved.route.matched.length > 0 &&
          resolved.route.name != "DefaultRoute"
        ) {
          setTimeout(async function() {
            if (queryString) {
              var keyValPairs = queryString.split("&");
              for (var pairNum in keyValPairs) {
                var key = keyValPairs[pairNum].split("=")[0];
                if (key.length > 0) {
                  if (typeof currentQuery[key] === "undefined")
                    currentQuery[key] = "";
                  currentQuery[key] = keyValPairs[pairNum].split("=")[1];
                }
              }
              currentQuery.q = queryString
                .replace("q=", "")
                .replace(/%20/g, " ");
              console.log("onDeepLinks currentQuery :::: ", currentQuery);
              _this.$router.push({
                path: data.path,
                query: currentQuery
              });
            } else {
              _this.$router.push(data.path);
            }
          }, 100);
        } else {
          if (this.$platform_is_cordova && data.url.indexOf("http") > -1) {
            //if app open on _system browser
            window.cordova.InAppBrowser.open(data.url, "_system");
          }
        }
      } else {
        if (this.$platform_is_cordova && data.url.indexOf("http") > -1) {
          //if app open on _system browser
          window.cordova.InAppBrowser.open(data.url, "_system");
        }
      }
    },
    onAppTrackingTransparency() {
      var _this = this;
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      if (_this.$platform_is_cordova && platform == "ios") {
        window.plugins.impacTracking.trackingAvailable(
          function(available) {
            if (!available) {
              console.log(
                "Tracciamento non disponibile o disabilitato dall'utente"
              );
              window.plugins.impacTracking.canRequestTracking(
                function(result) {
                  // Show results in the console
                  console.log(result);
                  if (result == true) {
                    window.plugins.impacTracking.requestTracking(
                      undefined,
                      function(res) {
                        console.log(res);
                      },
                      function(err) {
                        console.log(err);
                        //TODO: tracking non abilitato
                      }
                    );
                  }
                },
                function(err) {
                  console.log(err);
                  //TODO: c'è stato un errore: tracking non abilitato
                }
              );
            } else {
              window.plugins.impacTracking.requestTracking(
                undefined,
                function(res) {
                  console.log(res);
                },
                function(err) {
                  console.log(err);
                }
              );
            }
          },
          function(err) {
            console.log(err);
          }
        );
      }
    },
    async refreshLogin() {
      if (global.config.pushNotificationEnabled) {
        console.log("REFRESH LOGIN - REGISTER FIREBASE DEVICE");
        // Al Deviceready facciamo un refresh login per controllare se l'utente è ancora collegato
        // await SecurityService.refreshLogin();
        // await this.registerFirebaseDevice();
      }
    },
    ...mapActions({
      loadCart: "cart/loadCart"
    }),
    isPlatformIos() {
      if (this.isCordova && window.cordova.platformId.toLowerCase() === "ios") {
        this.platformIos = true;
      }
    },
    async checkMissingDisclaimer() {
      let response = await UserService.checkUserDisclaimer(2);
      if (
        response &&
        response.registrationModules &&
        response.registrationModules.length > 0
      ) {
        await this.openDialog(CheckDisclaimerDialog, {
          waitForResult: true,
          fullscreen: false
        });
      }
    }
  },
  async created() {
    var _this = this;
    _this.load404 = false;
    this.fetchFooterLinksMenu();
    try {
      this.i18nLoaded = true;
      if (
        window.sessionStorage.getItem("is-authenticated") ||
        window.sessionStorage.getItem("X-Ebsn-Session") ||
        window.localStorage.getItem("X-Ebsn-Account") ||
        window.location.href.indexOf("checkUser") !== -1
      ) {
        await _this.loadCart();
      }
    } finally {
      _this.loading = false;
    }
    //generate fingerprint
    const fp = await FingerprintJS.load({ monitoring: false });
    this.updateFingerprint(await fp.get());

    global.EventBus.$on("isNotLoggedIn", () => {
      _this.updateAuth();
      _this.handleLogin();
    });
    global.EventBus.$on("restart", () => {
      _this.updateLastStartTime();
      window.location.reload();
    });
    global.EventBus.$on("resetFilters", () => {
      _this.resetFilters(Math.random());
    });
    setTimeout(function() {
      _this.key = _this.key + 1;
    }, 3000);
    //eventi legati a cordova, partono solo al deviceready che viene scatenato da cordova quanto la app è pronta
    document.addEventListener(
      "deviceready",
      () => {
        setTimeout(function() {
          if (window.innerWidth < 600) {
            screen.orientation.lock("portrait");
          }
        }, 1500);

        //save last start time
        _this.updateLastStartTime();

        document.addEventListener("resume", _this.onResume, false);

        //TODO: cambiare gestione da config.js alla router clicked
        window.IonicDeeplink.route(
          global.config.deeplinks,
          function(match) {
            _this.resolveRoute(match.$link);
          },
          function(nomatch) {
            console.log("NOMATCH :::: ", nomatch);
            _this.resolveRoute(nomatch.$link);
          }
        );

        //con x secondi controllo se c'è una versione più recente
        this.checkVersion();

        this.isPlatformIos();

        // Plugin Keyboard
        window.addEventListener("keyboardDidHide", () => {
          // Describe your logic which will be run each time keyboard is closed.
          console.log("keyboardDidHide");
          _this.keyboardOpen = false;
        });
        window.addEventListener("keyboardDidShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardDidShow");
          _this.keyboardOpen = true;
        });
        window.addEventListener("keyboardWillShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardWillShow");
          //iOS fix
          _this.keyboardOpen = true;
        });
        window.addEventListener("keyboardWillHide", () => {
          // Describe your logic which will be run each time when keyboard is about to be closed.
          console.log("keyboardWillHide");
          //iOS fix

          _this.keyboardOpen = false;
        });

        this.refreshLogin();
      },
      false
    );
    if (this.isCordova) {
      //add platfrm to body element
      let platform = window.cordova.platformId;
      document.body.classList.add(platform);
    } else {
      //add platfrm to body element
      let platform = navigator.userAgentData
        ? navigator.userAgentData.platform
        : navigator.platform;
      document.body.classList.add(platform);
      this.checkOsx();
    }
  },
  beforeDestroy() {
    global.EventBus.$off("isNotLoggedIn");
    global.EventBus.$off("restart");
    global.EventBus.$off("resetFilters");
  },
  watch: {
    $route(to) {
      this.load404 = false;
      if (to.meta.load404) {
        this.load404 = to.meta.load404;
      }
    },
    // getUserId(newUserId, oldUserId) {
    //   if (newUserId != oldUserId) {
    //     let user = {};
    //     if (newUserId) {
    //       user = {
    //         external_id: newUserId,
    //         firstName: this.$store.state.cart.cart.user.firstName,
    //         lastName: this.$store.state.cart.cart.user.lastName,
    //         email: this.$store.state.cart.cart.user.email
    //       };
    //     }
    //     window.Freshbots.user(user);
    //   }
    // },
    isAuthenticated(val, oldVal) {
      if (val !== oldVal && val === true) {
        this.checkMissingDisclaimer();
      }
    }
  }
};
</script>
<style scoped lang="scss">
.sso-btn {
  top: 130px;
  z-index: 10;
}
.v-menu__content {
  border: 1px solid $warning !important;
  margin-top: 5px;
  min-width: 170px !important;
  z-index: 10 !important;
}
.links-list {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: 75%;
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    min-width: 50%;
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    min-width: 35%;
  }
}

.cart-info {
  // height: 100vh;
  .infos {
    background-color: var(--v-grey-lighten3) !important;
    height: #{$cart-info-height};
  }
  .summary {
    background: rgb(238, 238, 238);
    background: linear-gradient(
      rgba(238, 238, 238, 0.7) 0%,
      rgba(238, 238, 238, 1) 15%,
      rgba(238, 238, 238, 1) 100%
    );
    position: fixed;
    bottom: 0;
    width: 100%;

    height: #{$summary-height};
    border-top-color: var(--v-grey-lighten3);
  }
  .items-list {
    $background: $white;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: auto;
    .items-list {
      background: transparent;
    }
    .infos {
      height: auto;
      box-shadow: 0px 3px 6px #00000029 !important;
    }
    .summary {
      box-shadow: 0px -3px 6px #00000029 !important;
    }
    .cart-item-list-no-checkout-osx {
      padding-bottom: $summary-height-osx !important;
    }
  }
}
</style>
